.popover {
  /* transform: translate(144px, 67px) !important; */
  margin-left: -140px !important;
  border-radius: 13px;
  max-width: 379px;

  border: 2px solid rgba(0, 0, 0, 0.2) !important;
}
.popover .arrow {
  margin-left: 140px !important;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  border-bottom-color: #f3f3f3 !important;
}
.popover-filters {
  padding-inline: 0px;
  border-radius: 13px;
  padding-block: 0px !important;
  box-shadow: 0px 3px 6px #00000029;
}
.popover-filters ul {
  margin-bottom: 0px;
  padding-left: 0px;
  width: 100%;

  border-radius: 13px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-gap: 0px;
  flex-wrap: wrap;
  background: white 0% 0% no-repeat padding-box;
}
.popover-filters li {
  cursor: pointer;
  border-bottom: 1px solid grey;
  padding: 1rem 22px;
  font: normal normal normal 15px Basis-Grotesque-Pro-Regular;
  letter-spacing: 0px;
  width: 100%;
  color: #000000;
  display: flex;
  position: relative;
}

.popover-filters ul li:nth-child(-n + 5)::before {
  border-right: 1px solid grey;
  position: absolute;
  content: "";
  height: 55%;
  transform: translateY(0%);
  right: 0px;
  top: 23%;
}

.popover-filters ul li:last-child::after,
.popover-filters ul li:nth-child(5)::after {
  border-bottom: 0px;
  border-right: 0px;
}

.popover-filters ul li.liItems:hover,
.popover-filters ul li.liItems.selected {
  /* border-top-right-radius: 13px; */
  color: #62b550;
  /* font-weight: bold; */
}

.popover-filters li.liItems:last-child:hover,
.popover-filters li.liItems:last-child.selected {
  /* border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px; */
  color: #62b550;
  /* font-weight: bold; */
}

.popover-filters li.liItems:first-child:hover,
.popover-filters li.liItems:first-child.selected {
  /* border-top-left-radius: 13px; */
  /* background: #e1ecde 0% 0% no-repeat padding-box; */
  /* border-top-right-radius: 13px; */
  /* border-bottom: 1px solid grey; */
  color: #62b550;
  /* font-weight: bold; */
}

.popover-filters li.liItems:nth-child(6):hover,
.popover-filters li.liItems:nth-child(6).selected {
  /* border-top-right-radius: 13px; */
  /* background: #e1ecde 0% 0% no-repeat padding-box; */
  /* border-top-right-radius: 13px; */
  color: #62b550;
  /* font-weight: bold; */
}

.popover-filters li.liItems:nth-child(5):hover,
.popover-filters li.liItems:nth-child(5).selected {
  /* border-bottom-left-radius: 13px; */
  /* background: #e1ecde 0% 0% no-repeat padding-box; */
  /* border-top-right-radius: 13px; */
  border-bottom: 1px solid grey;
  color: #62b550;
  /* font-weight: bold; */
}
