.test-img-container {
  height: 120px;
  width: 120px;
  border: 2px solid #dee2df;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 28px 10px 1px;
  transition: 0.5s;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px;
}
.test-img-url {
  height: 120px;
  width: 120px;
  object-fit: contain;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  box-shadow: 4px 2px 13px 0px #00000029;
}
.approved-img-logo {
  width: 16px;
  margin-bottom: 4px;
  margin-left: 4px;
}
.test-img-border {
  border-bottom: 1px solid hsl(26deg 4% 33%);
}

/* .test-img-border :nth-last-child(1) {
  border-bottom: 1px solid hsl(26deg 4% 33%);

}
.test-img-border :nth-child(1) {
} */
